body {
  background: url(./bg.jpg) no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.transparent {
  background:#7f7f7f;
  background:rgba(255,255,255,0.5);
}

.bg-virclar {
  background-color: #5180AD;
}